
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  name: string;
  contentable: {
    id: number;
    translation: {
      form_id: number;
      name: string;
    };
  };
};

@Component
export default class FormComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  key = 0;
  formData = {};
  successMessage = false;

  forms: Array<object> = [
    { component: "DamageReport", type: "DAMAGE_REPORT" },
    { component: "CivilStatus", type: "CIVIL_STATUS" },
    { component: "BadgeOrder", type: "BADGE_ORDER" },
    { component: "ParkingSpaceRent", type: "PARKING_SPACE_RENT" },
    { component: "PetPermit", type: "PET_PERMIT" },
    { component: "SublettingApplication", type: "SUBLETTING_APPLICATION" },
    { component: "BankAccount", type: "BANK_ACCOUNT" },
    { component: "VariousConcerns", type: "VARIOUS_CONCERNS" },
    { component: "ApartmentAccessPermission", type: "APARTMENT_ACCESS_PERMISSION" }
  ];

  get buildingComplexData() {
    return this.$store.getters["app/buildingComplexData"];
  }

  get managementCompany() {
    return this.buildingComplexData?.management_company;
  }

  get form() {
    return this.forms[this.tile.contentable.id - 1];
  }

  get formComponent() {
    return () => import(`./forms/${this.form["component"]}`);
  }

  sendForm(): void {
    this.$store
      .dispatch("form/send", {
        formType: this.form["type"],
        formData: this.formData
      })
      .then(() => {
        this.scrollTop();

        this.successMessage = true;
        this.formData = {};
        this.key++;

        setTimeout(() => (this.successMessage = false), 3000);
      });
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
